<template>
  <li
    v-if="canViewHorizontalNavMenuHeaderGroup(item)"
    class="dropdown nav-item"
    :class="{
      'sidebar-group-active active open': isActive,
      'show': isOpen,
    }"
    @mouseenter="() => updateGroupOpen(true)"
    @mouseleave="() => updateGroupOpen(false)"
  >
    <b-link class="nav-link dropdown-toggle d-flex align-items-center">
      <feather-icon
        size="14"
        :icon="item.icon"
      />
      <span>{{ t(item.header) }}</span>
    </b-link>
    <ul class="dropdown-menu">
      <component
        :is="resolveHorizontalNavMenuItemComponent(child)"
        v-for="child in item.children"
        :key="child.title"
        :item="child"
      />
    </ul>
  </li>
</template>

<script>

    import HorizontalNavMenuGroup from '../horizontal-nav-menu-group/HorizontalNavMenuGroup.vue';
    import HorizontalNavMenuLink from '../horizontal-nav-menu-link/HorizontalNavMenuLink.vue';
    import mixinHorizontalNavMenuHeaderGroup from './mixinHorizontalNavMenuHeaderGroup';
    import useHorizontalNavMenuHeaderGroup from './useHorizontalNavMenuHeaderGroup';
    import { resolveHorizontalNavMenuItemComponent } from '@core/layouts/utils';
    import { useUtils as useI18nUtils } from '@core/libs/i18n';
    import { useUtils as useAclUtils } from '@core/libs/acl';

    export default {
        components: {
            HorizontalNavMenuGroup,
            HorizontalNavMenuLink
        },
        mixins: [mixinHorizontalNavMenuHeaderGroup],
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        setup(props) {
            const {
                isActive,
                updateIsActive,
                isOpen,
                updateGroupOpen
            } = useHorizontalNavMenuHeaderGroup(props.item);

            const { t } = useI18nUtils();
            const { canViewHorizontalNavMenuHeaderGroup } = useAclUtils();

            return {
                isOpen,
                isActive,
                updateGroupOpen,
                updateIsActive,
                resolveHorizontalNavMenuItemComponent,

                // ACL
                canViewHorizontalNavMenuHeaderGroup,

                // i18n
                t
            }
        }
    }

</script>
