import { resources } from '@/libs/acl/resources';

export default [{
    header: 'Subsidiarias',
    key: 'essac_supervisory_companies',
    icon: 'ApertureIcon',
    children: [
        {
            title: 'Lista',
            route: 'supervisory-companies-list',
            icon: 'ListIcon',
            resource: resources.ESSAC_SUPERVISORS_COMPANIES
        },
        {
            title: 'Contactos',
            route: 'supervisors-list',
            icon: 'UsersIcon',
            resource: resources.ESSAC_SUPERVISORS_COMPANIES
        }
    ]
}];
