import { resources } from '@/libs/acl/resources';

export default [{
    header: 'Personal',
    key: 'essac_students',
    icon: 'SmileIcon',
    children: [
        {
            title: 'Lista',
            route: 'students-list',
            icon: 'ListIcon',
            resource: resources.ESSAC_STUDENTS
        }
    ]
}];
